import React from 'react';

import PersonalInfo from "./PersonalInfo";
import StudentFields from "./StudentFields";
//import AddressFields from "./AddressFields";
import {imisState, stateDetails} from "../config";
import ReCAPTCHA from "react-google-recaptcha";
import { Field } from 'formik';

function SignupMemberForm(props) {
    const values = props.values;
    const ChapterCode = props.values.ChapterCode;
    const stage = props.values.stage;
    const errors = props.errors;
    const touched = props.touched;
    const initData = stage !== '' && ChapterCode !== '';
    const isImisState = imisState.indexOf(ChapterCode) >= 0;
    const isStudent = stage === 'student';
    const newJoinFormState = ChapterCode === 'NSW' || ChapterCode === "SA"

    return (
        <div>
            {initData && isStudent && !newJoinFormState &&
                <div>
                <PersonalInfo
                    errors = {props.errors}
                    touched = {props.touched}
                    values = {props.values}
                    handleChange = {props.handleChange}
                    handleBlur = {props.handleBlur}
                    handleSubmit = {props.handleSubmit}
                    isSubmitting = {props.isSubmitting}
                    setFieldValue = {props.setFieldValue}
                />
                <StudentFields
                    errors = {errors}
                    touched = {touched}
                    values = {values}
                    handleChange = {props.handleChange}
                    handleBlur = {props.handleBlur}
                    handleSubmit = {props.handleSubmit}
                    isSubmitting = {props.isSubmitting}
                />

                    <div className="row">
                        <div className="large-12 columns">
                            <ReCAPTCHA
                                sitekey="6LecJbEZAAAAADYTbeJ6haLJsPPXVwZw3KCNGkE6"
                                onChange={(value) => {console.log(value); props.setFieldValue('recaptcha', value);}}
                            />
                            <Field type="hidden" name="recaptcha" />
                        </div>
                    </div>
                <div className="row">
                    <div className="large-12 columns">
                        <small className="form-error is-visible">{errors.recaptcha}</small>
                        <small className="form-error is-visible">{errors.VALIDATION}</small>
                    </div>
                </div>
                    <div className="row">
                        <div className="large-12 columns">
                <button type="submit" className="button expanded success" disabled={props.isSubmitting}>
                    {!props.isSubmitting && <span>Join AMA as Student Member</span> } {props.isSubmitting && <i className='material-icons spin'>autorenew</i> }
                </button>
                        </div>
                    </div>
                </div>
            }
            {initData && isStudent && newJoinFormState && <div className="row">
                <div className="large-12 columns">
                    <h3>Join online at <a href="https://www.ama.com.au/membership"><strong>ama.com.au/membership</strong></a></h3>
                    <p>You can join by:</p>
                    <ul>
                        <li>Completing our new online form at <a href="https://www.ama.com.au/membership"><strong>ama.com.au/membership</strong></a></li>
                        <li>Contacting the friendly AMA {ChapterCode} Membership Team on {stateDetails[ChapterCode]['phone']}.</li>
                    </ul>
                </div>
            </div>
            }
            {initData && !isStudent && isImisState && <div>
                <PersonalInfo
                    errors = {errors}
                    touched = {touched}
                    values = {values}
                    handleChange = {props.handleChange}
                    handleBlur = {props.handleBlur}
                    handleSubmit = {props.handleSubmit}
                    isSubmitting = {props.isSubmitting}
                    setFieldValue = {props.setFieldValue}
                />

                {/*<div className="row">*/}
                    {/*<div className="large-12 columns">*/}
                        {/*<h3>Home Address</h3>*/}
                    {/*</div>*/}
                {/*</div>*/}
                {/*<AddressFields*/}
                    {/*errors = {errors}*/}
                    {/*touched = {touched}*/}
                    {/*values = {values}*/}
                    {/*handleChange = {props.handleChange}*/}
                    {/*handleBlur = {props.handleBlur}*/}
                    {/*handleSubmit = {props.handleSubmit}*/}
                    {/*isSubmitting = {props.isSubmitting}*/}
                {/*/>*/}
                <div className="row">
                    <div className="large-12 columns">
                        <ReCAPTCHA
                            sitekey="6LecJbEZAAAAADYTbeJ6haLJsPPXVwZw3KCNGkE6"
                            onChange={(value) => {console.log(value); props.setFieldValue('recaptcha', value);}}
                        />
                    </div>
                </div>
                <div className="row">
                    <div className="large-12 columns">
                        <small className="form-error is-visible">{touched.recaptcha && errors.recaptcha}</small>
                        <small className="form-error is-visible">{errors.VALIDATION}</small>
                    </div>
                </div>
                <div className="row">
                    <div className="large-12 columns">
                        <button type="submit" className="button expanded success" disabled={props.isSubmitting}>
                            {!props.isSubmitting && <span>Next</span> } {props.isSubmitting && <i className='material-icons spin'>autorenew</i> }
                        </button>
                    </div>
                </div>
            </div>
            }
            {initData && !isImisState && !isStudent && <div className="row">
                <div className="large-12 columns">
                    <h3>Join online at the AMA ({ChapterCode}) website</h3>
                    <p>You can join by:</p>
                    <ul>
                        <li>Join online at <strong><a href={stateDetails[ChapterCode]['web']} className="text-muted">AMA {ChapterCode} website</a></strong></li>
                        <li>Phone the friendly AMA {ChapterCode} Membership Team on {stateDetails[ChapterCode]['phone']}.</li>
                        <li>Or email {stateDetails[ChapterCode]['email']}</li>
                    </ul>
                </div>
            </div>
            }
            { !initData &&
            <p>Please select your membership state and career stage</p>
            }
        </div>
    );


}

export default SignupMemberForm;
