import React from 'react';

//export const apiurl = 'http://fed-d8.test';
export const apiurl = 'https://www.ama.com.au';
// export const apiurl = 'http://ama.ddev.site';
//export const apiurl = 'https://stage.ama.com.au';

export const stateList = [
    {"value":"", "label":"Select"},
    {"value":"ACT", "label":"Australian Capital Territory"},
    {"value":"NSW", "label":"New South Wales"},
    {"value":"NT", "label":"Northern Territory"},
    {"value":"QLD", "label":"Queensland"},
    {"value":"SA", "label":"South Australia"},
    {"value":"TAS", "label":"Tasmania"},
    {"value":"VIC", "label":"Victoria"},
    {"value":"WA", "label":"Western Australia"},
];
export const stateLookup = {};
stateLookup.ACT = 'ACT';
stateLookup.NSW = 'New South Wales';
stateLookup.NT = 'Northern Territory';
stateLookup.QLD = 'Queensland';
stateLookup.SA = 'South Australia';
stateLookup.TAS = 'Tasmania';
stateLookup.VIC = 'Victoria';
stateLookup.WA = 'Western Australia';

export const imisState = ['ACT', 'QLD', 'NT', 'TAS'];
export const asmofState = ['QLD', 'NT', 'TAS'];
export const wpissueState = ['QLD'];
export const mgmState = ['QLD', 'ACT'];

export const stateCardType = {};
stateCardType.ACT = ['AMEX', 'MC', 'VISA'];
stateCardType.QLD = ['AMEX', 'MC', 'VISA'];
stateCardType.TAS = ['AMEX', 'DINERS', 'MC', 'VISA'];
stateCardType.NT = ['AMEX', 'MC', 'VISA'];

export const paymentMethod = {};
paymentMethod.ACT = ['cc'];
paymentMethod.QLD = ['cc'];//, 'bpay', 'payroll'
paymentMethod.TAS = ['cc'];
paymentMethod.NT = ['cc'];
export const paymentMethodLookup = {};
paymentMethodLookup.cc = 'Credit card';
paymentMethodLookup.bpay = 'Bpay';
paymentMethodLookup.payroll = 'Payroll Deductions (QLD Health employees only)';
paymentMethodLookup.actpayroll = 'Payroll Deductions (ACT Health employees only)';

export const yearList = ['2023', '2024', '2025', '2026', '2027', '2028', '2029', '2030', '2031'];
export const monthList = [
    {"value":"01", "label":"Jan"},
    {"value":"02", "label":"Feb"},
    {"value":"03", "label":"Mar"},
    {"value":"04", "label":"Apr"},
    {"value":"05", "label":"May"},
    {"value":"06", "label":"Jun"},
    {"value":"07", "label":"Jul"},
    {"value":"08", "label":"Aug"},
    {"value":"09", "label":"Sep"},
    {"value":"10", "label":"Oct"},
    {"value":"11", "label":"Nov"},
    {"value":"12", "label":"Dec"},
];

export const careerStage = {};
careerStage.ACT = ['student', 'dit', 'dr'];
careerStage.NT = ['student', 'dit', 'dr'];
careerStage.QLD = ['student', 'dit', 'dr'];//, 'pm'
careerStage.TAS = ['student', 'dit', 'dr'];
careerStage.NSW = ['student', 'dit', 'dr'];
careerStage.SA = ['student', 'dit', 'dr'];
careerStage.VIC = ['student', 'dit', 'dr'];
// careerStage.WA = ['student', 'dit', 'dr'];
careerStage.WA = ['student'];

export const csm = {};
csm.student = 'Student';
csm.dit = 'Doctor in Training / GP Registrar';
csm.gp = 'GP Registrar';
csm.dr = 'Doctor';
csm.pm = 'Practice Manager';

export const csmnt = {};
csmnt.student = 'Student';
csmnt.dit = 'Doctor in Training';
csmnt.dr = 'Doctor';

export const careerStageLookup = {};

careerStageLookup.ACT = csm;
careerStageLookup.NT = csmnt;
careerStageLookup.QLD = csm;
careerStageLookup.TAS = csm;
careerStageLookup.NSW = csm;
careerStageLookup.SA = csm;
careerStageLookup.VIC = csm;
careerStageLookup.WA = csm;


export const prefixByCareer = {};
prefixByCareer.student = ['mr','ms','mrs','miss'];
prefixByCareer.dit = ['dr','aprof', 'prof'];
prefixByCareer.gp = ['dr','aprof', 'prof'];
prefixByCareer.dr = ['dr','aprof', 'prof'];
prefixByCareer.pm = ['mr','ms','mrs','miss'];

export const prefixLookup = {};
prefixLookup.dr = 'Dr';
prefixLookup.aprof = 'A/Prof';
prefixLookup.prof = 'Prof';
prefixLookup.mr = 'Mr';
prefixLookup.ms = 'Ms';
prefixLookup.mrs = 'Mrs';
prefixLookup.miss = 'Miss';

export const membershipCategoryLookup = {};
membershipCategoryLookup.FPS1='Full time specialist';
membershipCategoryLookup.FPP1='Full time general practitioner';

membershipCategoryLookup.F1Y1='First year after graduation (intern) / GP Registrar Year 1';
membershipCategoryLookup.F2Y1='Second year after graduation / GP Registrar Year 2';
membershipCategoryLookup.F3Y1='Third year after graduation / GP Registrar Year 3';
membershipCategoryLookup.F4Y1='Fourth year after graduation (DIT)';
membershipCategoryLookup.F5Y1='Fifth year after graduation (DIT)';
membershipCategoryLookup.F6Y1='Sixth year or more after graduation';

membershipCategoryLookup.FPH1='Part-time no more than 2 half days per week';
membershipCategoryLookup.FPT1='Part-time 0-10 hours per week';
membershipCategoryLookup.FPT2='Part-time 11-20 hours per week';//ACT has diff name // no more than 5 half days
membershipCategoryLookup.FSR1='Salaried Medical Officer with PP Rights or Specialist Quals';
membershipCategoryLookup.FSS1='Salaried/Career Medical Officer';//ACT has diff name

export const membershipCategories = {};
membershipCategories.ACT = {};
membershipCategories.ACT.dit = ['F1Y1','F2Y1','F3Y1','F4Y1','F5Y1'];
membershipCategories.ACT.gp = ['FPP1', 'FPT1', 'FPT2', 'FSR1', 'FSS1'];
membershipCategories.ACT.dr = ['FPP1', 'FPS1', 'FPT1', 'FPT2', 'FSR1', 'FSS1'];
membershipCategories.QLD = {};
membershipCategories.QLD.dit = ['F1Y1','F2Y1', 'F3Y1','F4Y1','F5Y1'];
membershipCategories.QLD.gp = ['FPP1', 'FPH1', 'FPT2', 'FSS1'];
membershipCategories.QLD.dr = ['FPP1', 'FPS1', 'FPH1', 'FPT2', 'FSS1'];
membershipCategories.TAS = {};
membershipCategories.TAS.dit = ['F1Y1','F2Y1','F3Y1','F4Y1','F5Y1'];
membershipCategories.TAS.gp = ['FPP1', 'FPH1', 'FPT2', 'FSR1', 'FSS1'];
membershipCategories.TAS.dr = ['FPP1', 'FPS1', 'FPH1', 'FPT2', 'FSR1', 'FSS1'];
membershipCategories.NT = {};
membershipCategories.NT.dit = ['F1Y1','F2Y1','F3Y1','F4Y1','F5Y1'];
membershipCategories.NT.gp = ['FPP1', 'FPH1', 'FPT2', 'FSR1', 'FSS1'];
membershipCategories.NT.dr = ['FPP1', 'FPS1', 'FPH1', 'FPT2', 'FSR1', 'FSS1'];

export const membershipType = {};
let ditgp = [
    {"value":"", "label":"Select"},
    {"value":"F1Y1", "label":"1st Year Doctor in Training"},
    {"value":"F2Y1", "label":"2nd Year Doctor in Training"},
    {"value":"F3Y1", "label":"3rd Year Doctor in Training"},
    {"value":"F4Y1", "label":"4th Year Doctor in Training"},
    {"value":"F5Y1", "label":"5th Year Doctor in Training"},
    {"value":"F6Y1", "label":"6th Year Doctor in Training"},
    {"value":"GRFT1", "label":"Full Time GP Registrar Year 1"},
    {"value":"GRFT2", "label":"Full Time GP Registrar Year 2"},
    {"value":"GRFT3", "label":"Full Time GP Registrar Year 3"},
    {"value":"GRPT1", "label":"Part Time GP Registrar Year 1"},
    {"value":"GRPT2", "label":"Part Time GP Registrar Year 2"},
    {"value":"GRPT3", "label":"Part Time GP Registrar Year 3"},
    {"value":"ML", "label":"Parental Leave"},
];

membershipType.dit = ditgp;
membershipType.gp = ditgp;

membershipType.dr = [
    {"value":"", "label":"Select"},
    {"value":"FAA1", "label":"Academic/Post Grad"},
    // {"value":"FBB1", "label":"Overseas"},
    {"value":"FPP1", "label":"GP - Private Practice"},
    {"value":"FPS1", "label":"Spec - Private Practice"},
    {"value":"FPT1", "label":"PT 0-10 hrs wk (NT up to 5 half days)"},
    {"value":"FPT2", "label":"PT 11-20 hrs wk or upto 5 half days pw"},
    {"value":"FPT3", "label":"PT 21-30 hrs wk"},
    {"value":"FRR1", "label":"Retired"},
    {"value":"FSS1", "label":"Salaried Med Officer"},
    {"value":"FVV1", "label":"Over 70"},
    {"value":"ML", "label":"Parental Leave"},
];

membershipType.sp = [
    {"value":"", "label":"Select"},
    // {"value":"FBB1", "label":"Overseas"},
    {"value":"FPS1", "label":"Spec - Private Practice"},
    {"value":"FPT1", "label":"PT 0-10 hrs wk (NT up to 5 half days)"},
    {"value":"FPT2", "label":"PT 11-20 hrs wk or upto 5 half days pw"},
    {"value":"FPT3", "label":"PT 21-30 hrs wk"},
    {"value":"FRR1", "label":"Retired"},
    {"value":"FSS1", "label":"Salaried Med Officer"},
    {"value":"FVV1", "label":"Over 70"},
    {"value":"ML", "label":"Parental Leave"},
];
membershipType.pm = [
    {"value":"", "label":"Select"},
    {"value":"PM", "label":"Practice Manager"},
];

export const craftGroup = [
    {"value":"", "label":"Select"},
    {"value":"ANAE","label":"Anaesthetists"},
    {"value":"DERM","label":"Dermatologists"},
    {"value":"DIT","label":"Doctor in Training"},
    {"value":"EMER","label":"Emergency Physicians"},
    {"value":"GENP","label":"General Practitioners"},
    {"value":"NONC","label":"Non Clinical"},
    {"value":"NA","label":"Not applicable"},
    {"value":"OBGY","label":"Obstetricians & Gynaecologists"},
    {"value":"OPHT","label":"Ophthalmologists"},
    {"value":"ORTH","label":"Orthopaedic Surgeons"},
    {"value":"PAED","label":"Paediatricians"},
    {"value":"PATH","label":"Pathologists"},
    {"value":"PHYS","label":"Physicians"},
    {"value":"PSYC","label":"Psychiatrists"},
    {"value":"RADI","label":"Radiologists"},
    {"value":"SURG","label":"Surgeons"}
];

export function getSimpleListOptions(options) {
    return options.map(option => <option value={option} key={option}>{option}</option>)
}

export function getOptions(options) {
    return options.map(option => <option value={option.value} key={option.value}>{option.label}</option>)
}
export function keyArrayToObjArray(options, dataArray) {
    return options.map(function (key) {
        return {"value":key, "label":dataArray[key]};
    });
}

export const stateDetails = {};
stateDetails.QLD = {};
stateDetails.QLD.web = 'https://qld.ama.com.au';
stateDetails.QLD.phone = '07 3872 2222';
stateDetails.QLD.email = 'membership@amaq.com.au';
stateDetails.ACT = {};
stateDetails.ACT.web = 'https://act.ama.com.au';
stateDetails.ACT.phone = '02 6270 5410';
stateDetails.ACT.email = 'reception@ama-act.com.au';
stateDetails.TAS = {};
stateDetails.TAS.web = 'https://tas.ama.com.au';
stateDetails.TAS.phone = '03 6223 2047';
stateDetails.TAS.email = 'ama@amatas.com.au';
stateDetails.NT = {};
stateDetails.NT.web = 'https://nt.ama.com.au';
stateDetails.NT.phone = '08 8981 7479';
stateDetails.NT.email = 'amant@amant.com.au';
stateDetails.NSW = {};
stateDetails.NSW.web = 'https://www.amansw.com.au/join-the-ama/';
stateDetails.NSW.phone = '02 9439 8822';
stateDetails.NSW.email = 'members@amansw.com.au';
stateDetails.SA = {};
stateDetails.SA.web = 'https://members.amasa.org.au/join';
stateDetails.SA.phone = '08 8361 0108';
stateDetails.SA.email = 'membership@amasa.org.au';
stateDetails.WA = {};
stateDetails.WA.web = 'https://www.amawa.com.au/amamembership/join-the-ama/';
stateDetails.WA.phone = '08 9273 3055';
stateDetails.WA.email = 'membership@amawa.com.au';
stateDetails.VIC = {};
stateDetails.VIC.web = 'https://amavic.com.au';
stateDetails.VIC.phone = '1800 810 451 or 03 9280 8722 ';
stateDetails.VIC.email = 'amavic@amavic.com.au';

export const countries = [
    {"value":"AF","label":"AFGHANISTAN"},
    {"value":"AX","label":"Aland Islands"},
    {"value":"AL","label":"ALBANIA"},
    {"value":"DZ","label":"ALGERIA"},
    {"value":"AS","label":"American Samoa"},
    {"value":"AD","label":"ANDORRA"},
    {"value":"AO","label":"ANGOLA"},
    {"value":"AI","label":"Anguilla"},
    {"value":"AQ","label":"Antarctica"},
    {"value":"AG","label":"Antigua and Barbuda"},
    {"value":"AR","label":"ARGENTINA"},
    {"value":"AM","label":"Armenia"},
    {"value":"AW","label":"ARUBA"},
    {"value":"AU","label":"AUSTRALIA"},
    {"value":"AT","label":"AUSTRIA"},
    {"value":"AZ","label":"Azerbaijan"},
    {"value":"BS","label":"BAHAMAS"},
    {"value":"BH","label":"BAHRAIN"},
    {"value":"BD","label":"BANGLADESH"},
    {"value":"BB","label":"BARBADOS"},
    {"value":"BY","label":"Belarus"},
    {"value":"BE","label":"BELGIUM"},
    {"value":"BZ","label":"BELIZE"},
    {"value":"BJ","label":"BENIN"},
    {"value":"BM","label":"BERMUDA"},
    {"value":"BT","label":"BHUTAN"},
    {"value":"BO","label":"BOLIVIA"},
    {"value":"BA","label":"Bosnia and Herzegovina"},
    {"value":"BW","label":"BOTSWANA"},
    {"value":"BV","label":"Bouvet Island"},
    {"value":"BR","label":"BRAZIL"},
    {"value":"IO","label":"British Indian Ocean Terr"},
    {"value":"BN","label":"Brunei Darussalam"},
    {"value":"BG","label":"BULGARIA"},
    {"value":"BF","label":"Burkina Faso"},
    {"value":"BI","label":"BURUNDI"},
    {"value":"CV","label":"Cabo Verde"},
    {"value":"KH","label":"CAMBODIA"},
    {"value":"CM","label":"CAMEROON"},
    {"value":"CA","label":"CANADA"},
    {"value":"KY","label":"Cayman Islands"},
    {"value":"CF","label":"Central African Republic"},
    {"value":"TD","label":"Chad"},
    {"value":"CL","label":"CHILE"},
    {"value":"CN","label":"CHINA"},
    {"value":"CX","label":"Christmas Island"},
    {"value":"CC","label":"Cocos (Keeling) Islands"},
    {"value":"CO","label":"COLOMBIA"},
    {"value":"KM","label":"Comoros"},
    {"value":"CG","label":"Congo"},
    {"value":"CD","label":"Congo, The Dem Rep Of The"},
    {"value":"CK","label":"Cook Islands"},
    {"value":"CR","label":"COSTA RICA"},
    {"value":"CI","label":"Cote d'Ivoire"},
    {"value":"HR","label":"Croatia"},
    {"value":"CU","label":"CUBA"},
    {"value":"CY","label":"CYPRUS"},
    {"value":"CZ","label":"Czech Republic"},
    {"value":"DK","label":"DENMARK"},
    {"value":"DJ","label":"Djibouti"},
    {"value":"DM","label":"Dominica"},
    {"value":"DO","label":"DOMINICAN REPUBLIC"},
    {"value":"EC","label":"ECUADOR"},
    {"value":"EG","label":"EGYPT"},
    {"value":"SV","label":"EL SALVADOR"},
    {"value":"GQ","label":"Equatorial Guinea"},
    {"value":"ER","label":"Eritrea"},
    {"value":"EE","label":"Estonia"},
    {"value":"ET","label":"ETHIOPIA"},
    {"value":"FK","label":"Falkland Is (Malvinas)"},
    {"value":"FO","label":"Faroe Islands"},
    {"value":"FJ","label":"FIJI"},
    {"value":"FI","label":"FINLAND"},
    {"value":"FR","label":"FRANCE"},
    {"value":"GF","label":"FRENCH GUIANA"},
    {"value":"PF","label":"FRENCH POLYNESIA"},
    {"value":"TF","label":"French Southern Terr"},
    {"value":"GA","label":"GABON"},
    {"value":"GM","label":"GAMBIA"},
    {"value":"GE","label":"Georgia"},
    {"value":"DE","label":"GERMANY"},
    {"value":"GH","label":"GHANA"},
    {"value":"GI","label":"Gibraltar"},
    {"value":"GR","label":"GREECE"},
    {"value":"GL","label":"GREENLAND"},
    {"value":"GD","label":"Grenada"},
    {"value":"GP","label":"GUADELOUPE"},
    {"value":"GU","label":"Guam"},
    {"value":"GT","label":"GUATEMALA"},
    {"value":"GN","label":"GUINEA"},
    {"value":"GW","label":"Guinea-Bissau"},
    {"value":"GY","label":"GUYANA"},
    {"value":"HT","label":"HAITI"},
    {"value":"HM","label":"Heard Is and McDonald Is"},
    {"value":"VA","label":"Holy See (Vatican City)"},
    {"value":"HN","label":"HONDURAS"},
    {"value":"HK","label":"HONG KONG"},
    {"value":"HU","label":"HUNGARY"},
    {"value":"IS","label":"ICELAND"},
    {"value":"IN","label":"INDIA"},
    {"value":"ID","label":"INDONESIA"},
    {"value":"IR","label":"Iran, Islamic Republic of"},
    {"value":"IQ","label":"IRAQ"},
    {"value":"IE","label":"IRELAND"},
    {"value":"IL","label":"ISRAEL"},
    {"value":"IT","label":"ITALY"},
    {"value":"JM","label":"JAMAICA"},
    {"value":"JP","label":"JAPAN"},
    {"value":"JO","label":"JORDAN"},
    {"value":"KZ","label":"Kazakhstan"},
    {"value":"KE","label":"KENYA"},
    {"value":"KI","label":"Kiribati"},
    {"value":"KP","label":"Korea, Dem People's Rep"},
    {"value":"KW","label":"KUWAIT"},
    {"value":"KG","label":"Kyrgyzstan"},
    {"value":"LA","label":"Lao People's Dem Republic"},
    {"value":"LV","label":"Latvia"},
    {"value":"LB","label":"LEBANON"},
    {"value":"LS","label":"LESOTHO"},
    {"value":"LR","label":"LIBERIA"},
    {"value":"LY","label":"Libyan Arab Jamahiriya"},
    {"value":"LI","label":"LIECHTENSTEIN"},
    {"value":"LT","label":"Lithuania"},
    {"value":"LU","label":"LUXEMBOURG"},
    {"value":"MO","label":"Macao"},
    {"value":"MK","label":"Macedonia"},
    {"value":"MG","label":"MADAGASCAR"},
    {"value":"MW","label":"MALAWI"},
    {"value":"MY","label":"MALAYSIA"},
    {"value":"MV","label":"Maldives"},
    {"value":"ML","label":"MALI"},
    {"value":"MT","label":"MALTA"},
    {"value":"MH","label":"Marshall Islands"},
    {"value":"MQ","label":"MARTINIQUE"},
    {"value":"MR","label":"Mauritania"},
    {"value":"MU","label":"MAURITIUS"},
    {"value":"YT","label":"Mayotte"},
    {"value":"MX","label":"MEXICO"},
    {"value":"FM","label":"Micronesia, Fed States Of"},
    {"value":"MC","label":"Monaco"},
    {"value":"MN","label":"MONGOLIA"},
    {"value":"MS","label":"Montserrat"},
    {"value":"MA","label":"MOROCCO"},
    {"value":"MZ","label":"MOZAMBIQUE"},
    {"value":"MM","label":"Myanmar"},
    {"value":"NA","label":"Namibia"},
    {"value":"NR","label":"Nauru"},
    {"value":"NP","label":"NEPAL"},
    {"value":"NL","label":"NETHERLANDS"},
    {"value":"AN","label":"Netherlands Antilles"},
    {"value":"NC","label":"NEW CALEDONIA"},
    {"value":"NZ","label":"NEW ZEALAND"},
    {"value":"NI","label":"NICARAGUA"},
    {"value":"NE","label":"NIGER"},
    {"value":"NG","label":"NIGERIA"},
    {"value":"NU","label":"Niue"},
    {"value":"NF","label":"Norfolk Island"},
    {"value":"MP","label":"Northern Mariana Islands"},
    {"value":"NO","label":"Norway"},
    {"value":"OM","label":"OMAN"},
    {"value":"PK","label":"PAKISTAN"},
    {"value":"PW","label":"Palau"},
    {"value":"PS","label":"Palestine"},
    {"value":"PS","label":"Palestinian Territory"},
    {"value":"PA","label":"PANAMA"},
    {"value":"PG","label":"PAPUA NEW GUINEA"},
    {"value":"PY","label":"PARAGUAY"},
    {"value":"PE","label":"PERU"},
    {"value":"PH","label":"PHILIPPINES"},
    {"value":"PN","label":"Pitcairn"},
    {"value":"PL","label":"POLAND"},
    {"value":"PT","label":"PORTUGAL"},
    {"value":"PR","label":"Puerto Rico"},
    {"value":"QA","label":"QATAR"},
    {"value":"KR","label":"Republic of Korea"},
    {"value":"MD","label":"Republic of Moldova"},
    {"value":"RE","label":"Reunion"},
    {"value":"RO","label":"ROMANIA"},
    {"value":"RU","label":"Russian Federation"},
    {"value":"RW","label":"RWANDA"},
    {"value":"SH","label":"Saint Helena"},
    {"value":"KN","label":"Saint Kitts and Nevis"},
    {"value":"LC","label":"Saint Lucia"},
    {"value":"PM","label":"Saint Pierre and Miquelon"},
    {"value":"WS","label":"Samoa"},
    {"value":"SM","label":"San Marino"},
    {"value":"ST","label":"Sao Tome and Principe"},
    {"value":"SA","label":"SAUDI ARABIA"},
    {"value":"SN","label":"SENEGAL"},
    {"value":"CS","label":"Serbia and Montenegro"},
    {"value":"SC","label":"SEYCHELLES"},
    {"value":"SL","label":"SIERRA LEONE"},
    {"value":"SG","label":"SINGAPORE"},
    {"value":"SK","label":"Slovakia"},
    {"value":"SI","label":"Slovenia"},
    {"value":"SB","label":"Solomon Islands"},
    {"value":"SO","label":"Somalia"},
    {"value":"ZA","label":"SOUTH AFRICA"},
    {"value":"GS","label":"South Georgia and the SSI"},
    {"value":"ES","label":"SPAIN"},
    {"value":"LK","label":"SRI LANKA"},
    {"value":"VC","label":"St Vincent and Grenadines"},
    {"value":"SD","label":"SUDAN"},
    {"value":"SR","label":"Suriname"},
    {"value":"SJ","label":"Svalbard and Jan Mayen"},
    {"value":"SZ","label":"SWAZILAND"},
    {"value":"SE","label":"SWEDEN"},
    {"value":"CH","label":"SWITZERLAND"},
    {"value":"SY","label":"Syrian Arab Republic"},
    {"value":"TW","label":"Taiwan, Province of China"},
    {"value":"TJ","label":"Tajikistan"},
    {"value":"TH","label":"THAILAND"},
    {"value":"TL","label":"Timor-Leste"},
    {"value":"TG","label":"TOGO"},
    {"value":"TK","label":"Tokelau"},
    {"value":"TO","label":"TONGA"},
    {"value":"TT","label":"Trinidad and Tobago"},
    {"value":"TN","label":"TUNISIA"},
    {"value":"TR","label":"TURKEY"},
    {"value":"TM","label":"Turkmenistan"},
    {"value":"TC","label":"Turks and Caicos Islands"},
    {"value":"TV","label":"Tuvalu"},
    {"value":"UG","label":"UGANDA"},
    {"value":"UA","label":"Ukraine"},
    {"value":"AE","label":"United Arab Emirates"},
    {"value":"GB","label":"UNITED KINGDOM"},
    {"value":"TZ","label":"United Rep of Tanzania"},
    {"value":"US","label":"United States"},
    {"value":"UY","label":"URUGUAY"},
    {"value":"UM","label":"US Minor Outlying Islands"},
    {"value":"UZ","label":"Uzbekistan"},
    {"value":"VU","label":"VANUATU"},
    {"value":"VE","label":"VENEZUELA"},
    {"value":"VN","label":"Viet Nam"},
    {"value":"VG","label":"Virgin Islands, British"},
    {"value":"VI","label":"Virgin Islands, US"},
    {"value":"WF","label":"Wallis and Futuna"},
    {"value":"EH","label":"Western Sahara"},
    {"value":"YE","label":"YEMEN"},
    {"value":"ZM","label":"ZAMBIA"},
    {"value":"ZW","label":"ZIMBABWE"}
];

export const joinPrice = {};
joinPrice.QLD = {};
joinPrice.QLD.F5Y1 = {"Monthly": 640.66, 'Annual':80.08};

joinPrice.ACT = {};
joinPrice.NT = {};
joinPrice.TAS = {};

joinPrice.ACT.FPS1 = {"Annual": 1923, 'Monthly':240.38};
joinPrice.ACT.FPP1 = {"Annual": 1752, 'Monthly':219};
joinPrice.ACT.F1Y1 = {"Annual": 416, 'Monthly':52};
joinPrice.ACT.F2Y1 = {"Annual": 572, 'Monthly':71.5};
joinPrice.ACT.F3Y1 = {"Annual": 729, 'Monthly':91.12};
joinPrice.ACT.F4Y1 = {"Annual": 833, 'Monthly':104.12};
joinPrice.ACT.F5Y1 = {"Annual": 936, 'Monthly':117};
joinPrice.ACT.F6Y1 = {"Annual": 0, 'Monthly':0};
joinPrice.ACT.FPH1 = {"Annual": 475, 'Monthly':59.38};
joinPrice.ACT.FPT1 = {"Annual": 475, 'Monthly':59.38};
joinPrice.ACT.FPT2 = {"Annual": 1055, 'Monthly':131.88};
joinPrice.ACT.FSR1 = {"Annual": 1923, 'Monthly':240.38};
joinPrice.ACT.FSS1 = {"Annual": 1442, 'Monthly':180.25};

joinPrice.QLD.FPS1 = {"Annual": 1054.66, 'Monthly':131.83};
joinPrice.QLD.FPP1 = {"Annual": 1054.66, 'Monthly':131.83};
joinPrice.QLD.F1Y1 = {"Annual": 316, 'Monthly':39.5};
joinPrice.QLD.F2Y1 = {"Annual": 385.33, 'Monthly':48.17};
joinPrice.QLD.F3Y1 = {"Annual": 443.32, 'Monthly':55.42};
joinPrice.QLD.F4Y1 = {"Annual": 537.32, 'Monthly':67.16};
joinPrice.QLD.F5Y1 = {"Annual": 640.66, 'Monthly':80.08};
joinPrice.QLD.F6Y1 = {"Annual": 0, 'Monthly':0};
joinPrice.QLD.FPH1 = {"Annual": 260, 'Monthly':32.5};
joinPrice.QLD.FPT1 = {"Annual": 260, 'Monthly':32.5};
joinPrice.QLD.FPT2 = {"Annual": 573.33, 'Monthly':71.67};
joinPrice.QLD.FSR1 = {"Annual": 0, 'Monthly':0};
joinPrice.QLD.FSS1 = {"Annual": 1054.65, 'Monthly':131.83};
joinPrice.QLD.PM = {"Annual": 0, 'Monthly':0};
joinPrice.NT.FPS1 = {"Annual": 1034.01, 'Monthly':129.25};
joinPrice.NT.FPP1 = {"Annual": 1034.01, 'Monthly':129.25};
joinPrice.NT.F1Y1 = {"Annual": 292.67, 'Monthly':36.58};
joinPrice.NT.F2Y1 = {"Annual": 464.67, 'Monthly':58.08};
joinPrice.NT.F3Y1 = {"Annual": 482, 'Monthly':60.25};
joinPrice.NT.F4Y1 = {"Annual": 523.2, 'Monthly':65.4};
joinPrice.NT.F5Y1 = {"Annual": 540, 'Monthly':67.5};
joinPrice.NT.F6Y1 = {"Annual": 0, 'Monthly':0};
joinPrice.NT.FPH1 = {"Annual": 0, 'Monthly':0};
joinPrice.NT.FPT1 = {"Annual": 377.34, 'Monthly':47.17};
joinPrice.NT.FPT2 = {"Annual": 610, 'Monthly':76.25};
joinPrice.NT.FSR1 = {"Annual": 1034.01, 'Monthly':129.25};
joinPrice.NT.FSS1 = {"Annual": 922.67, 'Monthly':115.33};
joinPrice.TAS.FPS1 = {"Annual": 1063.15, 'Monthly':132.89};
joinPrice.TAS.FPP1 = {"Annual": 1063.15, 'Monthly':132.89};
joinPrice.TAS.F1Y1 = {"Annual": 268.31, 'Monthly':33.54};
joinPrice.TAS.F2Y1 = {"Annual": 336.39, 'Monthly':42.05};
joinPrice.TAS.F3Y1 = {"Annual": 420.22, 'Monthly':52.53};
joinPrice.TAS.F4Y1 = {"Annual": 542.89, 'Monthly':67.86};
joinPrice.TAS.F5Y1 = {"Annual": 681.24, 'Monthly':85.16};
joinPrice.TAS.F6Y1 = {"Annual": 0, 'Monthly':0};
joinPrice.TAS.FPH1 = {"Annual": 0, 'Monthly':0};
joinPrice.TAS.FPT1 = {"Annual": 317.53, 'Monthly':39.69};
joinPrice.TAS.FPT2 = {"Annual": 557.22, 'Monthly':69.65};
joinPrice.TAS.FSR1 = {"Annual": 1063.15, 'Monthly':132.89};
joinPrice.TAS.FSS1 = {"Annual": 1063.15, 'Monthly':132.89};
joinPrice.TAS.PM = {"Annual": 0, 'Monthly':0};

